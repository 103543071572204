/* eslint-disable import/extensions */
/* eslint-disable no-nested-ternary */
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Env from "Consts/env";
import Cookies from "js-cookie";
import { ThousandSeparator } from "Helpers/utils";
import PropTypes from "prop-types";
import { loadPoint } from "@/Store/AlloPoint/lib/actions";
import ErrorText from "../ErrorText";
import Shimmer from "./shimmer";

const AlloPointTextNew = ({ ...props }) => {

	const getData = useSelector((state) => state);
	const dispatch = useDispatch();

	const [visible, setVisible] = useState(false);

	const loadDataPoint = () => {

		dispatch(
			loadPoint({
				payload: {
					Authorization: Cookies.get("token"),
					headers: Env.HEADERS_WALLET,
				},
			}),
		);

	};

	const togglePoint = () => {

		setVisible(!visible);

	};

	useEffect(() => {

		loadDataPoint();

	}, []);

	return (

		getData.storePoint.status === "fetch_loading"
			? (
				<Shimmer />
			)
			: getData.storePoint.status === "error"
				? <ErrorText onClick={loadDataPoint} />
				: (
					<div className="allo-point">
						<img src="https://image.femaledaily.com/web-assets/allo-navbar/allo_menu_mpc.svg" alt="Allo Point" onClick={props.onClickIcon} aria-hidden="true" />
						<div style={{ maxWidth: "calc(100% - 33px - .5rem)" }}>
							<p className={`fd-font-h6 my-0${visible ? "" : " hidden"}`} style={{ textAlign: "right" }}>
								{
									visible
										? ThousandSeparator(getData.storePoint?.data?.data?.responseData?.balance)
										: <>&#11044;&#11044;&#11044;&#11044;&#11044;&#11044;</>
								}
							</p>
							<div className="point-wrapper">
								<p
									className="my-0"
									style={{
										color: "#6C6D6D", fontSize: "0.75rem", lineHeight: "1rem", display: "flex",
									}}
								>
									MPC Points
									<span className={`fdicon-bs-eye${visible ? "-off" : ""} allo-icon`} onClick={togglePoint} aria-hidden="true" />
								</p>
							</div>
						</div>
						<style>
							{`
							.allo-point {
								font-family: Poppins, sans-serif;
								font-style: normal;
								display: flex !important;
								width: 100%;
								align-items: center;
								justify-content: space-between;
							}
							.allo-point:hover {
								background-color: #a9a9a91c;
							}
							.allo-point p {
								color: #3F3F3F;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
							.allo-point img {
								height: 33.33px;
								cursor: pointer;
							}
							.allo-point span {
								font-size: 10px;
								font-weight: 600;
								color: #4F4F4f;
								cursor: default;
							}
							.allo-point .hidden {
								color: #BDBDBD;
								font-size: 8px;
								letter-spacing: 2px;
							}
							.allo-point .point-wrapper {
								width: 100%;
								padding: 0;
								border: none;
								text-align: right;
								justify-content: end;
							}
							.allo-point .allo-icon {
								font-size: 15px;
								width: 15px;
								height: 15px;
								position: relative;
								display: inline;
								margin-left: 9px;
								cursor: pointer;
							}
						`}
						</style>
					</div>
				)

	);

};

export default AlloPointTextNew;

AlloPointTextNew.propTypes = {
	onClickIcon: PropTypes.func.isRequired,
};
