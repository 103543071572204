import React from "react";
import types from "prop-types";

const ErrorTextAllo = ({ ...props }) => (
	<>
		<div className="allo-error-text-wrapper" onClick={() => props.onClick()} aria-hidden>
			<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="allo-error-icon">
				<path fillRule="evenodd" clipRule="evenodd" d="M1.28324 4.99412C1.28324 3.10412 2.75658 1.22746 5.04991 1.22746C6.90191 1.22746 7.81791 2.59812 8.20791 3.32746H7.04991C6.96151 3.32746 6.87672 3.36258 6.81421 3.42509C6.7517 3.4876 6.71658 3.57239 6.71658 3.66079C6.71658 3.7492 6.7517 3.83398 6.81421 3.89649C6.87672 3.95901 6.96151 3.99412 7.04991 3.99412H9.04991C9.13832 3.99412 9.2231 3.95901 9.28561 3.89649C9.34813 3.83398 9.38324 3.7492 9.38324 3.66079V1.66079C9.38324 1.57239 9.34813 1.4876 9.28561 1.42509C9.2231 1.36258 9.13832 1.32746 9.04991 1.32746C8.96151 1.32746 8.87672 1.36258 8.81421 1.42509C8.7517 1.4876 8.71658 1.57239 8.71658 1.66079V2.86946C8.24724 2.04146 7.16058 0.560791 5.04991 0.560791C2.34124 0.560791 0.616577 2.78412 0.616577 4.99412C0.616577 7.20412 2.34124 9.42746 5.04991 9.42746C6.34591 9.42746 7.42458 8.91412 8.19258 8.13279C8.6001 7.71713 8.92338 7.22657 9.14458 6.68812C9.16121 6.64763 9.16971 6.60426 9.16958 6.56049C9.16945 6.51671 9.16071 6.47339 9.14384 6.433C9.12697 6.39261 9.10231 6.35593 9.07126 6.32507C9.04022 6.2942 9.0034 6.26976 8.96291 6.25312C8.92242 6.23649 8.87905 6.22799 8.83528 6.22812C8.7915 6.22825 8.74818 6.237 8.70779 6.25387C8.66739 6.27074 8.63072 6.2954 8.59985 6.32644C8.56899 6.35748 8.54455 6.3943 8.52791 6.43479C8.33953 6.89342 8.06426 7.31131 7.71724 7.66546C7.06991 8.32546 6.15991 8.76079 5.04991 8.76079C2.75658 8.76079 1.28324 6.88412 1.28324 4.99412Z" fill="#ED4A12" />
			</svg>
			<span className="allo-error-text">Couldn&#8242;t load</span>
		</div>
		<style>
			{`
				.allo-error-text-wrapper {
					display: block;
					cursor: pointer;
					display: flex;
					align-items: center;
				}
				.profile-popup {
					width: 285px !important;
				}

				.allo-error-text {
					font-family: Poppins, sans-serif;
					font-style: normal;
					font-size: 10px;
					line-height: 14px;
					font-weight: 400 !important;
					color: #ED4A12 !important;
					cursor: pointer !important;
				}
				.allo-error-icon {
					margin: 0 4px -1px 0;
				}
			`}
		</style>
	</>
);

export default ErrorTextAllo;

ErrorTextAllo.propTypes = {
	onClick: types.func,
};

ErrorTextAllo.defaultProps = {
	onClick: () => null,
};
