/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable react/no-danger */
import dynamic from "next/dynamic";
import Head from "next/head";
import {
	useCallback, useEffect, useRef, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
// import { setAjaxWpvq, setGoogleTags } from "Helpers/utils";
import useMutationObserver from "@rooks/use-mutation-observer";
import Env from "Consts/env";
import AlloMain from "@fdn/allo_profile_migration";
import Cookies from "js-cookie";
import Router from "next/router";
import AlloSectionProfilePopup from "Components/allo/AlloProfilePopupSection";
import { loadMenuDrawer } from "@/Store/MenuDrawer/lib/actions";
import { loadSearchArticles } from "@/Store/SearchArticles/lib/actions";
import Vars from "../consts/vars";
import {
	alexa,
	alexaNoScript,
	ScrollToUp, setFBPixel,
	setFBPixelsNoscript, setGoogleAnalytics, setGoogleAnalyticsNoscriptGaHost, setGTMBody,
	setGTMBody2, setGTMHeader,
	setGTMHeader2,
	setGTMupdated,
} from "../helpers/utils";

// const baseAppName = "Mommies Daily";
// let appName = "";

const NavbarHeader = dynamic(() => import("@fdn/navbar_header"));
const FooterMD = dynamic(() => import("@fdn/footer_md"));
const Navbar = dynamic(() => import("Components/navbar"));
const Banner = dynamic(() => import("Components/banner/banner"));
const AlloExploreModal = dynamic(() => import("Components/modal_allo/allo_explore_modal"));

export default function Layout({ ...props }) {

	const prop = props;

	// if (typeof prop.title !== "undefined") {

	// 	appName = `${baseAppName} - ${prop.title}`;

	// }

	const isMobile = prop.ismobile || false;

	useCallback(() => {

		ScrollToUp();

	}, []);

	const dispatch = useDispatch();
	const storeData = useSelector((state) => state);

	const [searchText, setSearchText] = useState("");
	const [isShowSearch, setShowSearch] = useState(false);
	const [isShowSearchResult, setShowSearchResult] = useState(false);
	const [isShowSearchInput, setShowSearchInput] = useState(false);
	const [isArticlesResultLoading, setArticlesResultLoading] = useState(false);
	const [isHidetopMenu, setToggleTopMenu] = useState(false);
	const [articles, setArticlesResult] = useState([]);
	const [ActiveMenuDrawer, setActiveMenuDrawer] = useState([]);

	const [isHaveAds, setIsHaveAds] = useState(false);
	const stickyBannerRef = useRef();
	useMutationObserver(
		stickyBannerRef,
		(mutationList) => {

			mutationList.forEach((mutation) => {

				mutation.addedNodes.forEach((node) => {

					if (node?.id?.indexOf("iframe") !== -1) {

						setIsHaveAds(true);

					}

				});

			});

		},
		{ childList: true, subtree: true },
	);

	const handleMenuDrawer = (params) => {

		const data = ActiveMenuDrawer.findIndex((item) => item === params.id);

		if (data === -1) {

			if (params.pos === 0) {

				setActiveMenuDrawer([params.id]);

			} else if (params.pos === 1) {

				if (ActiveMenuDrawer.length === 1) {

					setActiveMenuDrawer([...ActiveMenuDrawer, params.id]);

				} else {

					ActiveMenuDrawer.pop();
					setActiveMenuDrawer([...ActiveMenuDrawer, params.id]);

				}

			}

		} else {

			setActiveMenuDrawer(
				ActiveMenuDrawer.filter((item) => item !== params.id),
			);

		}

	};

	const updateSearchText = (e) => {

		if (
			(e.target.value.length >= 3 && !isMobile)
			|| (e.target.value.length > 0 && isMobile)
		) {

			setSearchText(e.target.value);

			setShowSearch(true);
			setArticlesResultLoading(true);
			setShowSearchResult(true);

		} else {

			setShowSearch(false);
			setArticlesResultLoading(false);
			setShowSearchResult(false);

		}

	};

	const [isShowAlloPopup, setShowAlloPopup] = useState(false);
	const [isAlloExplorePopup, setIsAlloExplorePopup] = useState(false);
	const onClickLoginSignup = () => {

		if (process.env.NEXT_PUBLIC_ALLO_STATUS === "ON") {

			if (isMobile) {

				// eslint-disable-next-line no-use-before-define
				setShowSidebar(false);

			}
			setShowAlloPopup(true);

		} else {

			window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${window.location.href}`;

		}

	};

	const submitSearch = (e) => {

		e.preventDefault();
		window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/search?q=${searchText}`;

	};

	const showSearch = () => {

		if (!isMobile) {

			setShowSearch(true);

		} else {

			setShowSearchInput(true);

		}

	};
	useEffect(() => {

		let interval;
		if (
			(searchText.length >= 3 && !isMobile)
			|| (searchText.length > 0 && isMobile)
		) {

			interval = setTimeout(() => {

				dispatch(
					loadSearchArticles({
						payload: {
							headers: Env.HEADERS,
							is_server: true,
							keyword: searchText,
						},
					}),
				);
				// setArticlesResultLoading(false);

			}, 1000);

		} else {

			setArticlesResult([]);

		}

		return () => clearTimeout(interval);

	}, [searchText]);

	useEffect(() => {

		if (Array.isArray(storeData.storeSearchArticles.data.data)) {

			if (storeData.storeSearchArticles.data.data.length > 0) {

				const temp = storeData.storeSearchArticles.data.data.map(
					(item) => ({
						...item,
						url: item.url.includes("/blog")
							? item.url.replace(/\/blog/g, "")
							: item.url,
					}),
				);

				setArticlesResult(temp);

			} else {

				setArticlesResult([]);

			}

		}

		setArticlesResultLoading(false);

	}, [storeData.storeSearchArticles]);

	useEffect(() => {

		if (!isMobile) {

			window.addEventListener("scroll", () => {

				if (document.documentElement.scrollTop > 84) {

					setToggleTopMenu(true);

				} else {

					setToggleTopMenu(false);

				}

			});

		}

		dispatch(
			loadMenuDrawer({
				// slug: "product",
			}),
		);

		if (!Cookies.get("alloPopup") && !Router.query.length && !prop.self.data.mpc_id) {

			setShowAlloPopup(true);

		} else {

			setShowAlloPopup(false);

		}

	}, []);

	// start of handle allo explore

	const [isShowSidebar, setShowSidebar] = useState(false);
	const handleAlloMenuNavbar = () => {

		if (isMobile) {

			setShowSidebar((state) => !state);

		}

		setIsAlloExplorePopup(true);

	};

	const handleCloseModalAllo = () => {

		setIsAlloExplorePopup(false);

	};

	// end of handle allo explore

	return (
		<>
			<Head>
				{/* <title>{appName}</title> */}
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>

				<meta name="facebook-domain-verification" content="da5fn7zef6itq2j7dw31pazw4k9gkr" />
				<script async src="https://newrevive.detik.com/delivery/asyncjs.php" />
				<script type="text/javascript" src="https://mommiesdaily.com/wp-includes/js/jquery/jquery.js?ver=1.12.4-wp" />
				<script type="text/javascript" src="https://mommiesdaily.com/wp-includes/js/jquery/jquery-migrate.min.js?ver=1.4.1" />
				<script src="//cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.19/jquery.touchSwipe.min.js" />
				<link
					href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Poppins:wght@400;500;600&family=Roboto:wght@400;500;600;700&display=swap"
					rel="stylesheet"
				/>
				{/* <link
					rel="apple-touch-icon-precomposed"
					sizes="114x114"
					href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/logo-md.png`}`}
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="72x72"
					href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/logo-md.png`}`}
				/>
				<link
					rel="apple-touch-icon-precomposed"
					sizes="57x57"
					href={`${`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/logo-md.png`}`}
				/> */}

				<link
					rel="stylesheet"
					type="text/css"
					href="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/font/icomoon/styles.css"
				/>

				<link
					rel="stylesheet"
					type="text/css"
					href="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/font/new_icomoon/styles.css"
				/>

				<link
					rel="stylesheet"
					type="text/css"
					href="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/font/fontastic/styles.css"
				/>

				<link rel="shortcut icon" href="https://mommiesdaily.com/wp-content/uploads/2020/08/md-23-x-23-px.png" />
				<link
					rel="stylesheet"
					type="text/css"
					charSet="UTF-8"
					href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
				/>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
				/>

				{/* quiz */}
				<script
					type="text/javascript"
					src={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/jsnew/jquery-1.11.2.min.js`}
					id="jquery-js"
				/>
				<script
					type="text/javascript"
					src="https://mommiesdaily.net/wp-includes/js/wp-embed.min.js?ver=5.5"
					id="wp-embed-js"
				/>

				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link
					rel="preconnect"
					href="https://fonts.gstatic.com"
					crossOrigin="true"
				/>

				<link href="https://fonts.googleapis.com/css2?family=Brygada+1918:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

				{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
					/* ALEXA */
					<script
						async
						custom-element="amp-analytics"
						src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"
					/>
				) : null}

				<script dangerouslySetInnerHTML={setGTMHeader()} />
				<script dangerouslySetInnerHTML={setGTMHeader2()} />

				{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
					/* Alexa */
					<script
						type="text/javascript"
						dangerouslySetInnerHTML={alexa()}
					/>
				) : (
					""
				)}

				{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
					<script dangerouslySetInnerHTML={setGoogleAnalytics()} />
				) : (
					""
				)}

				{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
					<script dangerouslySetInnerHTML={setFBPixel()} />
				) : (
					""
				)}
				<link rel="stylesheet" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/style/bundle.css" />

				{process.env.NEXT_PUBLIC_NODE_ENV === "production" && (<script src="https://www.googleoptimize.com/optimize.js?id=OPT-WJKLDJK" />) }
				{process.env.NEXT_PUBLIC_NODE_ENV === "production" && (<script async src="https://www.googletagmanager.com/gtag/js?id=UA-25013355-5" />) }
				{process.env.NEXT_PUBLIC_NODE_ENV === "production" && (<script dangerouslySetInnerHTML={setGTMupdated()} />) }

			</Head>

			{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
				<amp-analytics
					type="alexametrics"
					dangerouslySetInnerHTML={{
						__html: `
				<script type="application/json"> {"vars": { "atrk_acct": "5TYNs1O7kI20L7", "domain": "mommiesdaily.com" }}</script>`,
					}}
				/>
			) : (
				""
			)}

			<div dangerouslySetInnerHTML={{ __html: prop.adsHead }} />

			<div dangerouslySetInnerHTML={{ __html: prop.adsFoot }} />

			<NavbarHeader
				is_mobile={isMobile}
				isNewIcon
				type="monochrome"
				self={prop.self.data}
				nav_position="fixed"
				onClickLoginSignup={onClickLoginSignup} // custom login function
				logout_link={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/logout`} // custom logout link
				menu={Vars.top_menu}
				base_url={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
				main_link={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
				home_link="/"
				reviews_link={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
				sso_link={process.env.NEXT_PUBLIC_SSO_DOMAIN}
				profile_link={`${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/md/me?forceCreate=true`}
				setting_link={`${process.env.NEXT_PUBLIC_SSO_DOMAIN}/account/md`}
				sidebar_config={Vars.config_sidebar}
				sidebar_mommies={Vars.config_mommies}
				is_show_sidebar={isShowSidebar}
				toggle_sidebar={() => setShowSidebar(!isShowSidebar)}
				SisterSites={Vars.SisterSites}
				logo_id="id_logo_home"
				search_input_id="id_search_input_field_editorial"
				button_login_id="id_loginsignup_editorial"
				button_cart_id="id_carteditorial"
				button_notif_id="id_notifeditorial"
				submit_search={submitSearch}
				search_text={searchText}
				update_search_text={updateSearchText}
				close_search_suggest={() => {

					setSearchText("");
					setShowSearch(false);

				}}
				logo_img="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/icon/logo-md.svg"
				is_article_loading={isArticlesResultLoading}
				is_show_search_suggest={isShowSearch}
				is_show_search_result={isShowSearchResult}
				is_show_search={isShowSearchInput}
				isShowDownloadApp={false}
				show_search={showSearch}
				hide_search={() => {

					setShowSearch(false);
					setShowSearchInput(false);

				}}
				articles={articles}
				is_hide_top_menu={isHidetopMenu}
				dataDrawer={storeData.storeMenuDrawer}
				handleMenuDrawer={handleMenuDrawer}
				ActiveMenuDrawer={ActiveMenuDrawer}
				// allo explore handle
				handleAlloExplorer={handleAlloMenuNavbar} // allo explore handle
				profilePopupCustomElement={prop.self?.data?.mpc_id && process.env.NEXT_PUBLIC_ALLO_STATUS === "ON" && (
					<AlloSectionProfilePopup
						onClickAlloCoupon={handleAlloMenuNavbar}
					/>
				)}
			>
				<Navbar
					isMobile={isMobile}
					navbarMenu={prop.navbarMenu}
				/>
			</NavbarHeader>
			<div className="main-content">{prop.children}</div>
			{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
				<script
					dangerouslySetInnerHTML={setGoogleAnalyticsNoscriptGaHost()}
				/>
			) : (
				""
			)}

			{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
				<noscript dangerouslySetInnerHTML={setGTMBody()} />
			) : (
				""
			)}

			{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
				<noscript dangerouslySetInnerHTML={setGTMBody2()} />
			) : (
				""
			)}

			{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
				<noscript dangerouslySetInnerHTML={alexaNoScript()} />
			) : (
				""
			)}

			{["production", "staging"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? (
				<noscript dangerouslySetInnerHTML={setFBPixelsNoscript()} />
			) : (
				""
			)}

			<FooterMD
				isMobile={isMobile}
				footerSite={Vars.FOOTER_SITE}
				domainHome={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
			/>

			<div ref={stickyBannerRef}>
				{
					isMobile
						? (
							<Banner
								name="stickystaticbanner"
								placeholder="stickystaticbanner"
								isMobile={isMobile}
								class_name="ads-stickystaticbanner"
							/>
						)
						: (
							<Banner
								name="bottomframe"
								placeholder="bottomframe"
								isMobile={isMobile}
								class_name="ads-bottomframe"
							/>
						)
				}

			</div>

			{
				(process.env.NEXT_PUBLIC_ALLO_STATUS === "ON" && isShowAlloPopup)
					&& (
						<AlloMain
							isMobile={isMobile}
							query={{}}
							hostDomain={`.mommiesdaily.${["staging", "production"].includes(process.env.NEXT_PUBLIC_NODE_ENV) ? "com" : "net"}`}
							ssoDomain={process.env.NEXT_PUBLIC_SSO_DOMAIN}
							closeElement={() => setShowAlloPopup(false)}
							onClickMigrateAccount={() => {

								const pageReferer = window.location.href;
								window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${pageReferer}`;

							}}
							onClickLoginRegister={() => {

								const pageReferer = window.location.href;
								window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}?url=${pageReferer}`;

							}}
							helpUrl={`${process.env.NEXT_PUBLIC_ROOT_HOME}/help?tab=account`}
						/>
					)
			}

			<AlloExploreModal
				isMobile={isMobile}
				isShow={isAlloExplorePopup}
				onClick={handleCloseModalAllo}
			/>

			<style>
				{`	

					.ads-bottomframe {
						width: 100%;
						position: fixed;
						bottom: -4px;
						display: flex;
						justify-content: center;
						z-index:2
					}
					
					.ads-stickystaticbanner {
						width: 100%;
						position: fixed;
						bottom: -4px;
						display: flex;
						justify-content: center;
						z-index:2
					}
						.main-logo-wrapper {
							width: 173px;
							align-items: stretch;
						}

						.navbar-inner-content-wrapper {
							max-width: 1200px;
						}

						.mega-menu-wrapper {
							max-width: 1200px;
							margin: 0 auto;
							display: flex;
							justify-content: center;
						}

						.search-input-field input {
							max-width: calc(100% - 32px);
						}

						.sub-menu-title a {
							text-transform: uppercase;
						}

						.menu-popup {
							max-width: calc(100% - 48px);
						}

						img.sub-menu-item {
							max-width: 100%;
						}

						${isHidetopMenu && !isMobile
			? ".mega-menu .menus-wrapper a {font-size: 14px; margin-top: 10px; margin-bottom: 10px;}"
			: ""
		}				
						
						.navbar-header .btn-close {
							margin-left: 0;
						}

						.navbar-header .mega-menu {
							padding-bottom: 0;
						}

						.mega-menu-wrapper {
							width: 100%;
							max-width: 100%;
							margin-top: 12px;
							display: unset;
							justify-content: unset;
						}

						.navbar-header .search-popup-content {
							max-width: calc(100% - 32px);
						}

						.menu-popup {
							max-width: unset;
						}

						.menu-popup-title {
							justify-content: space-between;
						}
						.footer {
							padding-bottom: ${!isHaveAds ? "10px" : "60px"} !important;
						}
						
					`}
			</style>
			<style jsx global>
				{`

					// disable icon notif & cart
					// .new-icon {
					// 	display: none !important;
					// }
					.root-container {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.row-center {
						width: 480px;
						box-sizing: border-box;
						position: relative;
					}

					@media screen and (max-width: 425px) {
						.row-center {
							width: 100%;
						}
					}

					a {
						text-decoration: none;
					}

					input,
					textarea,
					button,
					select,
					a {
						-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
					}

					body {
						margin: 0px;
						max-width: 100%;
						overflow-x: hidden;
					}

					.fdn-modal {
            			animation: none !important;
					}

					// img {
					// 	pointer-events: none;
					// }
					#id_allodropdown {
						${process.env.NEXT_PUBLIC_ALLO_STATUS === "OFF" ? "display: none" : ""}
					}
				`}
			</style>
		</>
	);

}
